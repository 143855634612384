import React from "react";
// import { useNavigate } from "react-router-dom";
import logo from "../assets/bcdlogo.png";
import fin_logo from "../assets/logo.png";
import authService from "../services/authService";

const Header = ({ noLogin, logOut }) => {
  // const nav = useNavigate();
  return (
    <header className="flex-between">
      <div className="flex-center">
        <img src={logo} alt="logo" className="logo" width={100} />
        <span className="vLine ml1 mr1"></span>
        <img src={fin_logo} alt="logo" className="logo" width={100} />
      </div>
      {!noLogin && (
        <div className="flex-end">
          <ul className="mr2">
            <li className="link">Existing users</li>
          </ul>
          <button
            className="br login-btn"
            onClick={() =>
              window.open("https://airlines.finkraft.ai", "_blank")
            }
          >
            Login
          </button>
        </div>
      )}
      {logOut && (
        <div className="flex-end">
          <button
            className="br login-btn"
            onClick={() => {
              authService.logOut();
            }}
          >
            Logout
          </button>
        </div>
      )}
    </header>
  );
};

export default Header;
