import { useState } from "react";
import { BrowserRouter, Route, Routes, useNavigate } from "react-router-dom";
import "./App.css";
import CallbackContainer from "./components/Callback";
import Dashboard from "./components/Dashboard";
import Faq from "./components/Faq";
import Footer from "./components/Footer";
import Header from "./components/Header";
import HeroSec from "./components/HeroSec";
import Login from "./components/login_new/Login";
import OurOffering from "./components/OurOffering";
import RunBusiness from "./components/RunBusiness";
import SectionThree from "./components/SectionThree";
import SectionTwo from "./components/SectionTwo";
import TypeForms from "./components/TypeForms";
import logo from "./assets/bcdlogo.png";
import fin_logo from "./assets/logo.png";
import authService from "./services/authService";

const Home = () => {
  return (
    <div>
      <div className="sec-one-two">
        <div className="container">
          <Header />
          <br />
          <br />
          <HeroSec />
          <br />
          <br />
          <br />
          <br />
          <SectionTwo />
          <br />
          <br />
          <br />
        </div>
      </div>
      <br />
      <br />
      <br />
      <div className="container">
        <SectionThree />
        <br />
        <br />
        <br />
        <OurOffering />
        <br />
        <br />
        <br />
        <Faq />
      </div>
      <RunBusiness />

      <Footer />
    </div>
  );
};

export const NavBar = (props) => {
  const history = useNavigate();
  return (
    <nav className="nav-bar flex-between">
      <div className="flex-center">
        <div className="flex-center">
          <img src={logo} alt="logo" className="logo" width={100} />
          <span className="vLine ml1 mr1"></span>
          <img src={fin_logo} alt="logo" className="logo" width={100} />
        </div>
        <ul>
          {props.nav?.map((ele) => (
            <li
              onClick={() => {
                window.open(ele.value, "_blank");
              }}
            >
              {ele.label}
            </li>
          ))}
        </ul>
      </div>
      <button
        className="cancel-btn"
        style={{ marginRight: 30 }}
        onClick={() => {
          authService.logOut();
          history("/");
        }}
      >
        Logout
      </button>
    </nav>
  );
};

function App() {
  const [nav, setNav] = useState([]);
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route exact path={"/"} element={<Home />} />
          <Route exact path={"/register"} element={<TypeForms />} />
          <Route exact path={"/login"} element={<Login />} />
          <Route path={"/callback"} element={<CallbackContainer />} />
          <Route
            exact
            path={"/dashboard"}
            element={
              <>
                <NavBar nav={nav} />
                <Dashboard setNav={setNav} />
              </>
            }
          />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
