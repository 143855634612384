import React from "react";
// import einvoice from "../assets/einvoice.png";
// import vendorEligibility from "../assets/vendor-eligibility.png";
// import vendorScore from "../assets/vendor-score.png";
// import { Widget } from "@typeform/embed-react";
import { useLocation } from "react-router-dom";
import Header from "./Header";

// const StepCard = ({ title, active, img }) => {
//   return (
//     <div className={`step-card ${active ? "active" : ""}`}>
//       <img src={img} alt="step-img" />
//       <div>
//         <h3>{title}</h3>
//         <p>
//           Status: <span>Pending</span>
//         </p>
//       </div>
//     </div>
//   );
// };

const TypeForms = () => {
  // const [type, setType] = useState(1);
  const location = useLocation();
  // const forms = {
  //   1: `4rwGqHUqKarD2tsZ7C01kxZYnRYFZjpSH956rYj2u081FYWj7meBuVEgFKKHUSXybZHw1SN7rfCfaaZf5e8902v7JwzRJ4f5yqRf#emailid=${
  //     location.state?.email ? location.state?.email : ""
  //   }&typeofform=xxxxx`,
  //   3: `YNJxLgpz#emailid=${
  //     location.state?.email ? location.state?.email : ""
  //   }&typeofform=xxxxx`,
  //   2: `daVnigS5#emailid=${
  //     location.state?.email ? location.state?.email : ""
  //   }&typeofform=xxxxx`,
  //   tmc: `gjZotT8h#emailid=${
  //     location.state?.email ? location.state?.email : ""
  //   }&typeofform=xxxxx`,
  //   4: `l5vrcN9k#emailid=${
  //     location.state?.email ? location.state?.email : ""
  //   }&typeofform=xxxxx`,
  // };
  return (
    <div className="container">
      <Header noLogin={true} />
      <div className="mt1 d-flex type-form">
        <div className="w30 mr2">
          <h2>Welcome to BCD Travel</h2>
          <p>
            Please provide additional details for arranging a demo or a free
            trial.
          </p>
        </div>
        {/* <div className="steps">
          <StepCard
            img={einvoice}
            title={"Company Details"}
            active={type === 1}
          />
          <StepCard
            img={vendorEligibility}
            title={"Tax Contact"}
            active={type === 2}
          />
          <StepCard
            img={vendorScore}
            title={"Travel Contact"}
            active={type === 3}
          />
          <StepCard img={einvoice} title={"+ Add User"} active={type === 4} />
        </div> */}
      </div>
      <div className="w100 bb-line" />
      <div className="mt3">
        {/* <Widget
          id={forms[type]}
          style={{ height: "80vh" }}
          onSubmit={() => {
            if (type < 4) {
              setType((typ) => typ + 1);
            }
          }}
        /> */}
        {/* <iframe
          height="500px"
          width="100%"
          frameborder="0"
          allowTransparency="true"
          scrolling="auto"
          src="https://creatorapp.zohopublic.com/finkraft/bcd-app/form-embed/BCD/4rwGqHUqKarD2tsZ7C01kxZYnRYFZjpSH956rYj2u081FYWj7meBuVEgFKKHUSXybZHw1SN7rfCfaaZf5e8902v7JwzRJ4f5yqRf"
        ></iframe> */}
        <iframe
          height="500px"
          width="100%"
          frameborder="0"
          allowTransparency="true"
          scrolling="auto"
          title="BCD_Zoho_Register"
          src={
            "https://creatorapp.zohopublic.com/finkraft/bcd-app/form-embed/BCD_Details/gG8n3CsNTOsuJ8H0ujt7XJyOJPhpMtxmkfquZzMDVAv4O7Wu8ueBXWteZk71hMa0axQR1QwqzyZZCZOZmmJDAmWkHRgVyZnbxj9H?User_Email_ID=" +
            location.state.email
          }
        ></iframe>
      </div>
    </div>
  );
};

export default TypeForms;
