import React from "react";
import down from "../assets/down_arrow.svg";
const Faq = () => {
  return (
    <div className="faq">
      <h2>Frequently Asked Questions</h2>
      <ul>
        <li>
          <span>What is GST?</span>
          <img src={down} width={14} alt="down" />
        </li>
        <li>
          <span>How to enrol for GST?</span>
          <img src={down} width={14} alt="down" />
        </li>
        <li>
          <span>{"What are the benefits of Goods & Services Tax?"}</span>
          <img src={down} width={14} alt="down" />
        </li>
        <li>
          <span>What is Composition Scheme?</span>
          <img src={down} width={14} alt="down" />
        </li>
        <li>
          <span>
            Is input tax credit available under the Goods and Services Tax?
          </span>
          <img src={down} width={14} alt="down" />
        </li>
        <li>
          <span>Which taxes did GST replace?</span>
          <img src={down} width={14} alt="down" />
        </li>
        <li>
          <span>How many returns are required to be filed under GST?</span>
          <img src={down} width={14} alt="down" />
        </li>
        <li>
          <span>My data is stored in Tally. How can I import my data?</span>
          <img src={down} width={14} alt="down" />
        </li>
      </ul>
    </div>
  );
};

export default Faq;
