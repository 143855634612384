import React from "react";
import { useNavigate } from "react-router-dom";

const RunBusiness = () => {
  const navigate = useNavigate();
  return (
    <div className="run-business">
      <div className="container">
        <h3>Run Your Business With BCG</h3>
        <div className="email-input">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              navigate("/register", {
                state: { email: "sivakumarkondala.123@gmail.com" },
              });
            }}
          >
            <div className="p-relative">
              <input placeholder="Email Address" required type={"email"} />
              <button className="login-btn br-r">Start a free trail</button>
            </div>
          </form>
          <span className="">
            Need advanced features with powerful, off the shelf tools?{" "}
            <u>Book a demo</u>
          </span>
        </div>
      </div>
    </div>
  );
};

export default RunBusiness;
