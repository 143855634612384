import React from "react";
import learn1 from "../assets/learn1.svg";
import learn2 from "../assets/learn2.svg";
import learn3 from "../assets/learn3.svg";

const Card = (props) => {
  return (
    <div className="card">
      <img src={props.img} alt="card-img" className="card-img" />
      <div className="card-data">
        <h3>{props.title}</h3>
        <p>{props.desc}</p>
        <button>Learn More</button>
      </div>
    </div>
  );
};

const OurOffering = () => {
  return (
    <div className="our-offering">
      <div className="flex-center flex-col">
        <h2>Our Offerings</h2>
        <p className="subtitle">
          We help corporates extract error-free data from invoices. Using a
          robust, automated platform, we help you get all the GST credit you are
          entitled to, and get it in time!
        </p>
      </div>
      <div className="mt3 flex-between">
        <Card
          title={"Vendor Invoice Validation"}
          desc="Check vendor’s e-invoice eligibility and real-time reconciliation of data"
          img={learn2}
        />
        <Card
          title={"Airline Invoice Retrieval"}
          desc="Helping retrieve, read, Multiple way reconcile and store airline invoices for future audits"
          img={learn3}
        />
        <Card
          title={"Invoice Hosting Platform"}
          desc="Helping Airlines to Host their invoices for the corporates pan India to download."
          img={learn1}
        />
      </div>
    </div>
  );
};

export default OurOffering;
