import { useEffect } from "react";

import auth from "../services/authService";
import Cookie from "js-cookie";
import { useNavigate } from "react-router-dom";

const setLocalStorageItems = ({
  accessToken = null,
  expiresIn = null,
  email = null,
  organizationId = null,
  organizationName = null,
}) => {
  const expiresAt = new Date().getTime() + Number(expiresIn) * 1000 + 1000;

  accessToken && localStorage.setItem("accessToken", accessToken);
  expiresIn && localStorage.setItem("expiresAt", expiresAt);
  email && localStorage.setItem("email", email);
  organizationId && localStorage.setItem("organizationId", organizationId);
  organizationName &&
    localStorage.setItem("organizationName", organizationName);
  Cookie.set("accessToken", accessToken, { domain: ".finkraft.ai" });
  Cookie.set("email", email, { domain: ".finkraft.ai" });
};

const CallbackContainer = () => {
  const nav = useNavigate();
  useEffect(() => {
    const auth0CallbackFunction = async () => {
      try {
        const authResult = await auth.getAuthResult();
        const { accessToken, expiresIn } = authResult;
        const user = await auth.getUserInfo({
          accessToken,
        });

        const { email } = user;
        setLocalStorageItems({
          accessToken,
          expiresIn,
          email,
        });

        const referrer = localStorage.getItem("referrer");
        if (referrer) {
          localStorage.removeItem("referrer");
          nav(referrer);
        } else {
          nav(`/dashboard`);
        }
      } catch (error) {
        console.log(error);
        nav("/login");
      } finally {
      }
    };
    auth0CallbackFunction();
    // eslint-disable-next-line
  }, []);

  return null;
};

export default CallbackContainer;
