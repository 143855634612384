import React from "react";
import invoice from "../assets/vendor_invoice.svg";

const SectionTwo = () => {
  return (
    <div className="sec-two">
      <div className="left">
        <img src={invoice} alt="invoice" />
      </div>
      <div className="right">
        <h3>The platform manages complete GST reconciliation</h3>
        <div>
          <h2>5 Way Reconciliation</h2>
          <p>
            {
              "Our application reconciles airline & hotel invoices with booking data, GSTR-2A & other data sets for maximum GST input tax claim."
            }
          </p>
        </div>
        <div>
          <h2>AI Bot for Extraction of Invoices</h2>
          <p>
            {" "}
            Retrieve GST compliant tax invoices for airline and hotel expenses
            to support input tax credit claim.
          </p>
        </div>
        <div>
          <h2>{"Reconciliation & Escalations"}</h2>
          <p>
            Our application provides comprehensive reconciliation reports. Tools
            escalate invoice & data mismatch discrepancies for timely error
            rectification.
          </p>
        </div>
      </div>
      <br />
      <br />
      <br />
    </div>
  );
};

export default SectionTwo;
