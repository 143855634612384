import auth0 from "auth0-js";
import Cookies from "js-cookie";

import config from "../utils/config";

const {
  auth0: {
    domain,
    clientId: clientID,
    audience,
    responseType,
    redirectUri,
    returnTo,
  },
} = config;

class Auth {
  webAuth = new auth0.WebAuth({
    domain,
    clientID,
    redirectUri,
    audience,
    responseType,
    scope: "openid profile email",
  });

  setWebAuth = (rediredUrl) => {
    this.webAuth = new auth0.WebAuth({
      domain,
      clientID,
      rediredUrl,
      audience,
      responseType,
      scope: "openid profile email",
    });
  };

  emailLoginStart = ({ email }) => {
    return new Promise((resolve, reject) => {
      this.webAuth.passwordlessStart(
        { connection: "email", send: "code", email },
        (err, result) => {
          if (err) reject(err);
          resolve(result);
        }
      );
    });
  };

  smsLoginStart = ({ phoneNumber }) => {
    return new Promise((resolve, reject) => {
      this.webAuth.passwordlessStart(
        { connection: "sms", send: "code", phoneNumber: `+91${phoneNumber}` },
        (err, result) => {
          if (err) reject(err);
          resolve(result);
        }
      );
    });
  };

  emailLoginVerify = ({ email, verificationCode }) => {
    return new Promise((resolve, reject) => {
      this.webAuth.passwordlessVerify(
        {
          connection: "email",
          email,
          verificationCode,
        },
        (err, result) => {
          if (err) reject(err);
          resolve(result);
        }
      );
    });
  };

  smsLoginVerify = ({ phoneNumber, verificationCode }) => {
    return new Promise((resolve, reject) => {
      this.webAuth.passwordlessVerify(
        {
          connection: "sms",
          phoneNumber: `+91${phoneNumber}`,
          verificationCode,
        },
        (err, result) => {
          if (err) reject(err);
          resolve(result);
        }
      );
    });
  };

  getAuthResult = () => {
    return new Promise((resolve, reject) => {
      this.webAuth.parseHash((err, authResult) => {
        if (err) reject(err);
        resolve(authResult);
      });
    });
  };

  getUserInfo = ({ accessToken }) => {
    return new Promise((resolve, reject) => {
      this.webAuth.client.userInfo(accessToken, (err, result) => {
        if (err) reject(err);
        resolve(result);
      });
    });
  };

  logOut = () => {
    localStorage.clear();
    Cookies.remove("accessToken", {
      domain: ".finkraft.ai",
    });
    Cookies.remove("email", {
      domain: ".finkraft.ai",
    });
    Cookies.remove("expiresAt", {
      domain: ".finkraft.ai",
    });
    Cookies.set("isSSOLogin", false, {
      domain: ".finkraft.ai",
    });
    this.webAuth.logout({
      returnTo,
      clientID,
    });
  };

  isTokenValid = () => {
    return localStorage.getItem("accessToken") &&
      localStorage.getItem("expiresAt") > new Date().getTime()
      ? true
      : false;
  };
}

const authService = new Auth();

export default authService;
