const {
  protocol, // retrieve http or https
  hostname, // retrieve domain name eg:- qatar.finkraft.ai
  host, // retrieve domain name along with port number eg:- localhost:8000
} = window.location;

const prodConfig = {
  apiBaseUrl: "https://apis.finkraft.ai/api/v1",
  apiTimeout: 30000,
  intercomAppId: "tdyve18j",
  auth0: {
    domain: "shopelect.auth0.com",
    clientId: "Ppfj1JyyG8smHdYi2R1gPqSSCALD3F0L",
    redirectUri: `${protocol}//${hostname}/callback`,
    audience: "https://shopelect.auth0.com/api/v2/",
    responseType: "token",
    returnTo: `${protocol}//${hostname}`,
  },
};

const devConfig = {
  apiBaseUrl: "https://apis.finkraft.ai/api/v1",
  apiTimeout: 30000,
  intercomAppId: "tdyve18j",
  auth0: {
    domain: "shopelect.auth0.com",
    clientId: "Ppfj1JyyG8smHdYi2R1gPqSSCALD3F0L",
    redirectUri: `${protocol}//${host}/callback`,
    audience: "https://shopelect.auth0.com/api/v2/",
    responseType: "token",
    returnTo: `${protocol}//${host}`,
  },
};

const localConfig = {
  apiBaseUrl: "http://localhost:8000/api/v1",
  apiTimeout: 30000,
  intercomAppId: "tdyve18j",
  auth0: {
    domain: "shopelect.auth0.com",
    clientId: "Ppfj1JyyG8smHdYi2R1gPqSSCALD3F0L",
    redirectUri: `${protocol}//${host}/callback`,
    audience: "https://shopelect.auth0.com/api/v2/",
    responseType: "token",
    returnTo: `${protocol}//${host}`,
  },
};

const config = (function setConfig() {
  switch (process.env.REACT_APP_ENV) {
    case "production":
      return prodConfig;

    case "staging":
    case "development":
      return devConfig;

    case "local":
      return localConfig;

    default:
      return devConfig;
  }
})();

export default config;
