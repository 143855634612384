import React, { useState } from "react";
import back_arrow from "../../assets/arrow_back.svg";
import LoginImg from "../../assets/body_side1.png";
import logo from "../../assets/bcdlogo.png";
import fin_logo from "../../assets/logo.png";
import auth from "../../services/authService";
import "./login.css";

function validateEmail(email) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}
function validateMobile(phone) {
  const re = /^[1-9][0-9]{9}$/;
  return re.test(String(phone).toLowerCase());
}
const Step1 = (props) => {
  const [userName, setUserName] = useState("");
  const [loading, setLoading] = useState(false);

  return (
    <div>
      <div className="flex-start">
        <img src={logo} alt="logo" className="logo" />
        <span className="vLine ml1 mr1"></span>
        <img src={fin_logo} alt="logo" className="logo" />
      </div>
      <h3>Hi, Welcome back!</h3>
      <p className="mb2">Please sign in to your BCD account.</p>
      <form
        onSubmit={async (e) => {
          e.preventDefault();
          setLoading(true);
          let result;

          try {
            if (validateEmail(userName)) {
              result = await auth.emailLoginStart({ email: userName });
            }
            if (validateMobile(userName)) {
              result = await auth.smsLoginStart({ phoneNumber: userName });
            }
            if (!validateMobile(userName) && !validateEmail(userName)) {
              setLoading(false);
              alert("Enter valid email or mobile number");
            }
            if (result) {
              setLoading(false);
              props.setPhone(userName);
              props.setStep(2);
            }
          } catch (err) {
            setLoading(false);
            alert("Enter valid username");
          }
        }}
      >
        <div className="input-wrap">
          <label>Login ID</label>
          <input
            type="text"
            value={userName}
            placeholder="Email ID or Mobile Number"
            onChange={(e) => setUserName(e.target.value)}
          />
        </div>
        <br />
        <button className="proceed" disabled={loading} type="submit">
          {loading ? "Processing..." : "Proceed"}
        </button>
      </form>
      <br />
      <br />
    </div>
  );
};

const Step2 = (props) => {
  const [otp, setOtp] = useState("");
  const [loading, setLoading] = useState(false);
  // const orgId = useSelector((state) => state?.organization?.orgId?.data);
  // const dispatch = useDispatch();

  // useEffect(() => {
  //   if (orgId && orgId?.[0]) {
  //     localStorage.setItem("orgId", orgId?.[0]?.orgId);
  //   } else if (orgId && orgId.length === 0 && !localStorage.getItem("orgId")) {
  //     alert("You don't have organization assigned with this email.");
  //     dispatch({
  //       type: GetOrganizationId.RESET,
  //     });
  //     props.setStep(1);
  //   }
  // }, [orgId]);
  return (
    <div className="step2">
      <div className="flex-start">
        <img src={logo} alt="logo" className="logo" />
        <span className="vLine ml1 mr1"></span>
        <img src={fin_logo} alt="logo" className="logo" />
      </div>
      <h3>Verification Code</h3>
      <p className="mb2">
        Please type the verification code sent to your mobile number{" "}
        <strong>
          {validateEmail(props.phone) ? props.phone : "+91 " + props.phone}
        </strong>
      </p>
      <form
        onSubmit={async (e) => {
          e.preventDefault();
          setLoading(true);
          try {
            let result;
            if (props.id && props.reqToken) {
              localStorage.setItem(
                "referrer",
                `/login?id=${props.id}&reqToken=${props.reqToken}&step=3`
              );
            }
            if (validateEmail(props.phone)) {
              result = await auth.emailLoginVerify({
                email: props.phone,
                verificationCode: otp,
              });
            } else {
              result = await auth.smsLoginVerify({
                phoneNumber: props.phone,
                verificationCode: otp,
              });
            }
            if (result) {
              setLoading(false);
            }
          } catch (error) {
            setLoading(false);
            alert("Invalid OTP");
          }
        }}
      >
        <div className="input-wrap">
          <label>Enter OTP</label>
          <input
            type="text"
            placeholder="Enter OTP"
            value={otp}
            onChange={(e) => setOtp(e.target.value)}
          />
        </div>
        <br />

        <button className="proceed" disabled={loading} type="submit">
          {loading ? "Processing..." : "Submit"}
        </button>
      </form>
      <br />
      <br />
      <span
        className="changeMb"
        onClick={() => {
          props.setStep(1);
        }}
      >
        <img src={back_arrow} alt="back_arrow" />
        Change Mobile Number
      </span>
      {/* <br />
  <br />
  <p className="have-acc">Do not have a user Account?</p>
  <button className="signup-btn">SignUp</button> */}
    </div>
  );
};

const Login = () => {
  const [step, setStep] = useState(1);
  const [phone, setPhone] = useState("");

  return (
    <div className="d-grid login-sec">
      <div className="item1 mAuto">
        <div className="login-right">
          {step === 1 && <Step1 setStep={setStep} setPhone={setPhone} />}
          {step === 2 && <Step2 setStep={setStep} phone={phone} />}
        </div>
      </div>
      <div className="item2">
        <div className="login-left sec-two">
          <h3>The platform that manages your invoices</h3>
          <ul>
            <li>5 Way Reconciliation</li>
            <li>Reconcile with GST2A, PR and other datasets</li>
            <li>Link your GST account with the portal</li>
            <li>Automate the data flow</li>
          </ul>
          <ul>
            <li>AI Bot for Extraction of Invoices</li>
            <li>Extract Invoices from different websites</li>
            <li>Direct Invoices from Travel Partners</li>
            <li>Travel data from requisitions</li>
          </ul>
          <ul>
            <li>{"Reconciliation & Escalations"}</li>
            <li>Generate reports</li>
            <li>Escalate mismatches with partners</li>
            <li>Be complaint with RBI guidelines</li>
          </ul>
          <div className="login-text">
            <img src={LoginImg} alt={"login-img"} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
