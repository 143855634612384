import React from "react";
import invoice from "../assets/vendor_invoice.svg";

const SectionThree = () => {
  return (
    <div className="sec-three">
      <div className="left">
        {" "}
        <h3>
          {
            "Simple, intuitive & use-friendly dashboard to help understand ITC status & drive decision making"
          }
        </h3>
        <p>
          5-Way Reconciliation gives information of missing invoices, mismatches
          in data and non-filing of tax invoices in GST portal by vendors.
        </p>
      </div>
      <div className="right">
        <img src={invoice} alt="invoice" />
      </div>
      <br />
      <br />
      <br />
    </div>
  );
};

export default SectionThree;
