import React, { useEffect, useState } from "react";
// import { useDispatch } from "react-redux";
// import { isValidToken } from "../actions/organization/Actions";
// import authService from "../services/authService";
import Cookie from "js-cookie";
import hr_table from "../assets/hr_table.svg";
import file from "../assets/file_white.svg";
import jwt_decode from "jwt-decode";
import "./styles/dashboard.css";
import { API_BASE_URL } from "../utils/constants";
import axios from "axios";

const Dashboard = (props) => {
  // const dispatch = useDispatch();
  const [metadata, setMetadata] = useState(null);
  const [products, setProducts] = useState([]);
  const [online, setOnline] = useState(null);

  useEffect(() => {
    // dispatch(isValidToken(authService.isTokenValid()));

    Cookie.set("accessToken", localStorage.getItem("accessToken"), {
      domain: ".finkraft.ai",
    });
    Cookie.set("email", localStorage.getItem("email"), {
      domain: ".finkraft.ai",
    });
    Cookie.set("expiresAt", localStorage.getItem("expiresAt"), {
      domain: ".finkraft.ai",
    });
    Cookie.set("isSSOLogin", true, {
      domain: ".finkraft.ai",
    });

    let timeout = setTimeout(() => {
      window.open("http://bcd-airlines.finkraft.ai", "_self");
    }, 2000);

    if (localStorage.getItem("accessToken")) {
      let metaData = jwt_decode(localStorage.getItem("accessToken"))?.[
        "https://shopelect.gstinput.com/user_metadata"
      ];
      setMetadata(metaData);
    }
    try {
      axios
        .get(API_BASE_URL + "/products")
        .then((res) => {
          if (res && res?.data && res?.data?.status === false) {
            alert(res.data.message);
          } else if (res && res.data) {
            setProducts(res.data);
          }
        })
        .catch((err) => {
          console.log(err);
          alert(err?.response?.message, "Access token expired");
        });
    } catch (err) {
      console.log(err);
      alert(err.message, "Access token expired");
    }

    try {
      axios
        .post(API_BASE_URL + "/sso", {
          email: localStorage.getItem("email"),
        })
        .then((res) => {
          if (res && res?.data && res?.data?.success === false) {
            alert("Failed!");
          } else if (res && res.data) {
            console.log(res.data);
          }
        })
        .catch((err) => {
          console.log(err);
          alert("Exception");
        });
    } catch (err) {
      alert("Exception");
    }
    return () => {
      clearTimeout(timeout);
    };
    // eslint-disable-next-line
  }, []);

  return (
    <div className="container">
      <div className="card">
        <div className="title-sec">
          <h3>Welcome to BCD!</h3>
          <p>
            {/* Real-time identification of vendors eligibility for invoices and
            GSTR1, GSTR 3B filing records before making a payment. */}
          </p>
        </div>
        <div className="d-flex gap2 products mAuto w90">
          <div className="w70 left-sec">
            {products &&
              Array.isArray(products) &&
              products?.length > 0 &&
              products.map((ele) => (
                <div className="item">
                  <div>
                    <div className="product-title">
                      <span className="img-wrap">
                        <img src={file} alt="file" />
                      </span>
                      <span>{ele.productName}</span>
                    </div>
                    <p>{ele.productDescription}</p>
                  </div>
                  <div className="flex-between">
                    <span>Learn more</span>
                    {metadata?.productAccess &&
                    metadata?.productAccess
                      ?.split(",")
                      .includes(ele.productCode) ? (
                      <button
                        className={online === ele.productCode ? "online" : ""}
                        onClick={() => {
                          // window.open("https://qr.finkraft.ai/", "_blank");

                          props.setNav(
                            ele?.url?.split(",").map((item) => ({
                              label: item.split("|")?.[0],
                              value: item.split("|")?.[1],
                            }))
                          );

                          setOnline(ele.productCode);
                        }}
                      >
                        {online === ele.productCode && (
                          <div class="ring-container">
                            <div class="ringring"></div>
                            <div class="circle"></div>
                          </div>
                        )}
                        {online === ele.productCode ? "Online" : "Access Now"}
                      </button>
                    ) : (
                      <button
                        onClick={async () => {
                          try {
                            let res = await axios.post(
                              API_BASE_URL + "/products/sendMail",
                              {
                                productCode: ele.productCode,
                                emailId: ele.marketingContact,
                                clientName: ele.productName,
                                phoneNumber: ele.phoneNumber,
                              }
                            );
                            res = res.data;
                            if (res.status) {
                              alert(res.message);
                            } else {
                              alert(res.message);
                            }
                          } catch (err) {
                            alert(err.message || "Something went wrong!");
                          }
                        }}
                      >
                        Schedule a demo
                      </button>
                    )}
                  </div>
                </div>
              ))}
            {/* <div className="item">
              <div>
                <div className="product-title">
                  <span className="img-wrap">
                    <img src={file} alt="file" />
                  </span>
                  <span>E-invoicing</span>
                </div>
                <p>Ensure you are not making double the TDS amounts …</p>
              </div>
              <div className="flex-between">
                <span>Learn more</span>
                {metadata?.productAccess &&
                metadata?.productAccess?.split(",").includes("EI") ? (
                  <button>{online === "EI" ? "Online" : "Access Now"}</button>
                ) : (
                  <button>Schedule a demo</button>
                )}
              </div>
            </div>
            <div className="item">
              <div>
                <div className="product-title">
                  <span className="img-wrap">
                    <img src={file} alt="file" />
                  </span>
                  <span>Airline Invoices</span>
                </div>
                <p>Ensure you are not making double the TDS amounts …</p>
              </div>
              <div className="flex-between">
                <span>Learn more</span>
                {metadata?.productAccess &&
                metadata?.productAccess?.split(",").includes("Airlines") ? (
                  <button>{online === "AIR" ? "Online" : "Access Now"}</button>
                ) : (
                  <button>Schedule a demo</button>
                )}
              </div>
            </div>
            <div className="item">
              <div>
                <div className="product-title">
                  <span className="img-wrap">
                    <img src={file} alt="file" />
                  </span>
                  <span>ITR Acknowledgment</span>
                </div>
                <p>Ensure you are not making double the TDS amounts …</p>
              </div>
              <div className="flex-between">
                <span>Learn more</span>
                {metadata?.productAccess &&
                metadata?.productAccess?.split(",").includes("CYV") ? (
                  <button>{online === "CYV" ? "Online" : "Access Now"}</button>
                ) : (
                  <button>Schedule a demo</button>
                )}
              </div>
            </div>
            <div className="item">
              <div>
                <div className="product-title">
                  <span className="img-wrap">
                    <img src={file} alt="file" />
                  </span>
                  <span>Invoice Hosting</span>
                </div>
                <p>Ensure you are not making double the TDS amounts …</p>
              </div>
              <div className="flex-between">
                <span>Learn more</span>
                {metadata?.productAccess &&
                metadata?.productAccess?.split(",").includes("Qatar") ? (
                  <button>
                    {online === "QATAR" ? "Online" : "Access Now"}
                  </button>
                ) : (
                  <button>Schedule a demo</button>
                )}
              </div>
            </div>
            <div className="item">
              <div>
                <div className="product-title">
                  <span className="img-wrap">
                    <img src={file} alt="file" />
                  </span>
                  <span>Yatra</span>
                </div>
                <p>Ensure you are not making double the TDS amounts …</p>
              </div>
              <div className="flex-between">
                <span>Learn more</span>
                {metadata?.productAccess &&
                metadata?.productAccess?.split(",").includes("Yatra") ? (
                  <button>
                    {online === "YATRA" ? "Online" : "Access Now"}
                  </button>
                ) : (
                  <button>Schedule a demo</button>
                )}
              </div>
            </div> */}
          </div>
          <div className="w30 right-sec">
            <section>
              <img src={hr_table} alt="hr_table" width={200} />
              <h2>
                <br />
                Access our range of financial products that help you save more
                GST Credit.
              </h2>
            </section>
            <section>
              <h2>Success Tips</h2>
              <br />
              <p>
                Ensure you are not making double the TDS amounts by bringing all
                the ITR transactions over here and share acknowledgments
              </p>
            </section>
          </div>
        </div>
      </div>
      <br />
    </div>
  );
};

export default Dashboard;
