import React from "react";
import logo from "../assets/logo.png";
const Footer = () => {
  return (
    <footer>
      <ul>
        <li>
          <img src={logo} alt="logo" />
        </li>
        <li>
          1860 500 3742 <br /> team@finkraft.in
        </li>
      </ul>
      <ul>
        <li>Our Company</li>
        <li>About us </li>
        <li>Our Team </li>
        <li>Careers </li>
        <li>News Coverage</li>
        <li>Partners </li>
        <li>Contact Us</li>
      </ul>
      <ul>
        <li>Enterprise Products</li>
        <li>GST Softwares </li>
        <li>E-invoicing </li>
        <li>Qatar Software</li>
      </ul>
      <ul>
        <li>Resources</li>
        <li>Blog </li>
        <li>Case Studies </li>
        <li>Schedule a Demo </li>
        <li>Support </li>
        <li>FAQ</li>
      </ul>
      <ul>
        <li>Information</li>
        <li>Privacy Policy </li>
        <li>Terms & Conditions</li>
        <li>Grievance Policy </li>
        <li>Refund Policy</li>
      </ul>
    </footer>
  );
};

export default Footer;
