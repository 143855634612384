import React from "react";
import { useNavigate } from "react-router-dom";
import img1 from "../assets/body_side1.png";
import img2 from "../assets/body_side2.png";
import logo from "../assets/logo.png";
const HeroSec = () => {
  const navigate = useNavigate();
  const [email, setEmail] = React.useState("");
  return (
    <div className="heroSec">
      <div className="left">
        <h2>
          {
            "Get Correct Air & Hotel Invoices, Reconcile Expense & Tax Data, Claim Maximum GST Credit"
          }
        </h2>
        <p>
          Automated, user-friendly and end-to-end SaaS platform to help
          corporates minimise the time taken and efforts to claim maximum input
          tax credit
        </p>
        <div className="email-input">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              navigate("/register", {
                state: { email: email },
              });
            }}
          >
            <div className="p-relative">
              <input
                placeholder="Email Address"
                required
                type={"email"}
                onChange={(e) => setEmail(e.target.value)}
              />
              <button className="login-btn br-r">Start a free trail</button>
            </div>
          </form>
          <span className="">
            Need advanced features with powerful, off the shelf tools?{" "}
            <u>Book a demo</u>
          </span>
        </div>
        <div className="left-bottom">
          <img src={logo} alt="logo" />
          <span className="ml1">
            Serving the corporate customers of BCD Travel India Private Limited
            through collaboration with Finkraft.ai
          </span>
        </div>
      </div>
      <div className="right">
        <img src={img1} alt="body1" />
        <img src={img2} alt="body2" />
      </div>
    </div>
  );
};

export default HeroSec;
